@import url("https://fonts.googleapis.com/css?family=Caveat|Mr+Dafoe|Nothing+You+Could+Do|Patrick+Hand+SC");
body {
  margin: 0 auto;
  padding-bottom: 80px;
  background-color: #292b2c;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}

.fa:not(:hover) {
  color: white;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  height: 80px;
  width: 100vw;
  z-index: 100;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}
.navbar .navbar-brand h3 {
  font-size: 3vmax;
  padding-left: 1em;
}
.navbar .navbar-nav {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}
.navbar .navbar-nav a {
  margin-left: 1rem;
}
.navbar .checkbox {
  display: none;
}
.navbar .checkbox + label {
  display: none;
  width: 40px;
  height: 40px;
  padding: 30px;
}
.navbar .checkbox + label .icon-bar {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
}
.navbar .checkbox + label .ib-top,
.navbar .checkbox + label .ib-mid,
.navbar .checkbox + label .ib-bot {
  transform-origin: center;
  transition: all 250ms ease-in-out;
}
.navbar .checkbox + label .ib-top {
  transform: translateY(-5px);
}
.navbar .checkbox + label .ib-mid {
  transform: translateY(0);
}
.navbar .checkbox + label .ib-bot {
  transform: translateY(5px);
}
.navbar .checkbox:checked + label .ib-top {
  transform: translateY(3px) rotate(45deg);
}
.navbar .checkbox:checked + label .ib-mid {
  opacity: 0;
}
.navbar .checkbox:checked + label .ib-bot {
  transform: translateY(-3px) rotate(-45deg);
}

.sig {
  font-family: "Nothing You Could Do";
}

.wrapper {
  margin: 0 auto;
  padding: 1.5em;
  margin-top: 0px;
}
.wrapper .tableau {
  column-gap: 1em;
}
.wrapper .tableau .fa-times {
  color: #ccc;
  cursor: pointer;
}
.wrapper .tableau .fade {
  background-color: rgba(41, 43, 44, 0.9);
}
.wrapper .tableau .tile {
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 500;
}

.modal .lightbox {
  max-width: 90vw;
  max-height: 90vh;
  align-self: center;
}

.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Caveat";
  letter-spacing: 0.1em;
  position: fixed;
  bottom: 0;
  background: rgba(41, 43, 44, 0.99);
  height: 60px;
}

.footer a {
  font-size: 1.35em;
  margin: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.hidden {
  display: none;
}

.close-icon,
.close-icon:not(:hover) {
  color: red;
  position: absolute;
  right: 4vw;
  opacity: 0.75;
}

@media only screen and (max-width: 768px) {
  .navbar {
    box-shadow: 1px 0px 3px black;
    justify-content: space-between;
  }
  .navbar .checkbox + label {
    display: initial;
  }
  .navbar .checkbox ~ .navbar-nav {
    position: absolute;
    overflow: hidden;
    left: -1em;
    width: 100vw;
    transform: scale(1, 0);
    top: 80px;
    background-color: #292b2c;
    flex-direction: column;
    height: 120px;
    box-shadow: 1px 1px 3px black;
    padding: 1em;
    justify-content: space-around;
    transform-origin: top;
    transition: all 250ms ease-in-out;
  }
  .navbar .checkbox:checked + label ~ .navbar-nav {
    top: 82px;
    transform: scale(1, 1);
  }
}

@media only screen and (max-width: 554px) {
  .navbar .navbar-brand h3 {
    font-size: 2.25vmax;
  }

  .footer a {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 420px) {
  .navbar .navbar-brand h3 {
    font-size: 1.7vmax;
  }

  .footer {
    justify-content: space-around;
  }

  .footer a {
    font-size: 2em;
  }

  .footer .footer-text {
    display: none;
  }
}

@media only screen and (min-width: 400px) {
  .tableau {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media only screen and (min-width: 700px) {
  .tableau {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

  .close-icon {
    top: 4vh;
  }
}
@media only screen and (min-width: 900px) {
  .tableau {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media only screen and (min-width: 1100px) {
  .tableau {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
  }
}
@media only screen and (orientation: portrait) {
  .tableau {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }

  .footer {
    justify-content: space-around;
  }
  .footer a {
    font-size: 2em;
  }
  .footer .footer-text {
    display: none;
  }

  .close-icon {
    bottom: 4vh;
  }
}

@media only screen and (orientation: landscape) {
  .close-icon {
    top: 4vh;
  }
}
